<form class="group-form__main" [formGroup]="groupForm" (submit)="submitGroup()"
  [ngClass]="showModal ? 'group-form__main--show' : ''">

  <div class="group-form__input">
    <request-text-input [parentForm]="groupForm" [controlName]="'organization'" [title]="'Título de organización *'"
      [placeholder]="'Escriba el título'">
    </request-text-input>
  </div>

  <div class="group-form__input">
    <request-select-input [items]="typeRxGroupList" [parentForm]="groupForm" [controlName]="'type'" [title]="'Tipo *'"
      [placeholder]="'Elija el tipo'" (onSelectChange)="requiredCategory($event)">
    </request-select-input>
  </div>

  <div class="group-form__input" [ngClass]="this.groupForm.get('type')?.value === '' ? 'disabled': ''">
    <request-type-research-select-input [validation]="false" [defaultSelected]="defaultResearchLineIds" [title]="'Líneas de investigación'" (addedElements)="getRLinesToAdd($event)"
      [inputId]="'add-group-type-select'">
    </request-type-research-select-input>
  </div>

  <div class="group-form__input" [ngClass]="areaList.length === 0 ? 'disabled': ''">
    <request-select-input [items]="areaList" [parentForm]="groupForm" [controlName]="'area'" [title]="'Área *'"
      [placeholder]="'Elija el área'">
    </request-select-input>
  </div>

  <div class="group-form__input">
    <request-select-input [items]="schoolList" [parentForm]="groupForm" [controlName]="'school'" [title]="'Escuela *'"
      [placeholder]="'Seleccione la escuela'" (onSelectChange)="getUnitContent($event)">
    </request-select-input>
  </div>

  <div class="group-form__input"> <!-- [ngClass]="unityList.length === 0 ? 'disabled': ''" -->
    <request-select-input [items]="unityList" [parentForm]="groupForm" [controlName]="'unity'" [title]="'Unidad'"
      [placeholder]="'Seleccione la unidad'">
    </request-select-input>
  </div>

  <div class="group-form__input" *ngIf="groupForm.get('type')?.value == 3">
    <request-select-input [items]="categories" [parentForm]="groupForm" [controlName]="'category'" [title]="'Categoría'"
      [placeholder]="'Elija la categoría'">
    </request-select-input>
  </div>

  <div class="group-form__input" *ngIf="groupForm.get('type')?.value == 2">
    <request-checkbox-input [title]="'¿Pertenece a un semillero de investigación?'" [inputId]="'seedbed-checkbox'" 
      [parentForm]="groupForm" [controlName]="'seedbedCheckbox'" (checkBox)="requiredSeedGroup($event)">
    </request-checkbox-input>
  </div>

  <div class="group-form__input"
    *ngIf="groupForm.get('seedbedCheckbox')?.value === true && groupForm.get('type')?.value == 2"
    [ngClass]="areaList.length === 0 || groupForm.get( 'school' )?.value === '' || seedbedGroups.length === 0 ? 'disabled': ''">
    <request-select-input [items]="seedbedGroups" [parentForm]="groupForm" [controlName]="'seedbedGroup'" [title]="'Semilleros'"
      [placeholder]="'Elija el semillero'">
    </request-select-input>
  </div>
  
  <div class="group-form__input">
    <request-textarea-input [parentForm]="groupForm" [controlName]="'description'" [title]="'Descripción *'"
      [placeholder]="'Escriba la descripción del grupo'">
    </request-textarea-input>
  </div>

  <div class="group-form__input">
    <request-member-select-input [defaultSelectedUsers]="[selectedGroup.userID]" [maxMembers]="1" [title]="groupForm.get('type')?.value == 3 ? 'Líder de grupo *' : 
      groupForm.get('type')?.value == 1 ? 'Director de semillero *' : groupForm.get('type')?.value == 2 ? 'Director opción de grado *' : 
      'Líder de grupo *'" (addedMembers)="getManager($event)" [inputId]="'group-leader-select'">
    </request-member-select-input>
  </div>

  <div class="group-form__input">
    <request-date-input [controlName]="'startDate'" [parentForm]="groupForm" [title]="'Fecha de inicio *'">
    </request-date-input>
  </div>

  <div class="form-actions">
    <button class="button button--dark" [ngClass]="!( groupForm.valid && manager !== undefined ) || 
      isSubmitting ? 'button--disabled' : ''">
      Guardar
    </button>

    <button class="button button--cancel" type="button" (click)="closeGroupModal()">
      Cancelar
    </button>

    <button class="button button--delete" type="button" (click)="deleteGroup()">
      Eliminar
    </button>
  </div>
</form>

<div class="modal-bg" [ngClass]="showModal ? 'modal-bg--show' : ''"></div>